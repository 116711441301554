'use client';

import { StytchB2B } from '@stytch/nextjs/b2b';
import { StytchEventType } from '@stytch/vanilla-js';
import { merge } from 'lodash';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

import {
  loginConfig,
  loginStylesConfig,
  stytchStylesConfig,
} from '../../config';

const Login = () => {
  const router = useRouter();
  const [loaded, setLoaded] = useState(false);
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      {!loaded ? (
        <StytchB2B
          config={loginConfig}
          callbacks={{
            onEvent: (event) => {
              if (event.type === StytchEventType.AuthenticateFlowComplete) {
                setLoaded(true);
                router.replace('/share/overview');
              }
            },
          }}
          styles={merge({}, stytchStylesConfig, loginStylesConfig)}
        />
      ) : (
        <p>Successfully logged in</p>
      )}
    </div>
  );
};

export default Login;
